<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li>
          <p>状态：</p>
          <select @change="getData(1)" v-model="form.state">
            <option :value="0">全部</option>
            <option :value="1">未支付</option>
            <option :value="2">支付成功</option>
          </select>
        </li>
        <li>
          <p>下单时间：</p>
          <input type="date" @change="getData(1)" v-model="form.startDate"><span> &nbsp;-&nbsp; </span>
          <input type="date" @change="getData(1)" v-model="form.endDate">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td style="text-align:center">订单编号</td>
            <td style="text-align:center">下单时间</td>
            <td style="text-align:center">下单人</td>
            <td>门店</td>
            <td style="text-align:center">状态</td>
            <td>订单内容</td>
            <td style="text-align:center">支付成功时间</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td style="text-align:center">{{item.id+200000}}</td>
            <td style="text-align:center">{{dataFormat(item.caozuoshijian)}}</td>
            <td style="text-align:center">{{item.caozuorenName }}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td style="text-align:center"><span :class="returnLBClass(item.state)">{{returnLB(item.state)}}</span></td>
            <td>{{getNameAndSon(item.name,item.sonId)}}</td>
            <td style="text-align:center">{{dataFormat(item.success_time)}}</td>
            <td>
              <div class="buttonGroup">
                <button type="button" v-if="countState(item.state,item.caozuoshijian)" class="suc" @click="showEWM(item.code_url)">重新支付</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <div class="meg_xiaotu" v-if="ewmShow">
      <div class="meg_xiaotu_box">
        <div class="meg_xiaotu_a">
          <img :src="dataURL">
          <p>请微信扫码支付(二维码有效期2个小时)</p>
        </div>
        <div class="meg_xiaotu_b">
          <div class="meg_xiaotu_b_1" @click="getDingdanState()">支付完成</div>
          <div class="meg_xiaotu_b_2" @click="getDingdanState()">关闭</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
import {payData} from '@/data/data'
import qrcode from 'qrcode'
export default {
  name: 'OperationRecord',
  data() {
    return {
      dataList:[],
      payData,
      ewmShow:false,
      dataURL:'',
      form:{
        state:0,
        startDate:'',
        endDate:'',
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
    }
  },
  created() {
     this.getData(1)
  },
  components: {},
  computed:{
    countState(){
      return (state,time)=>{
        if(state==2||moment().diff(moment(time),'hours')>2){
          return false
        }else{
          return true
        }
      }
    }
  },
  mounted() {},
  methods: {
    getDingdanState(){
      this.ewmShow=false
      this.getData()
    },
    showEWM(url){
      qrcode.toDataURL(url,{
        width:300,
        height:300,
        margin:3
      },(err,baseurl)=>{
        if(err){
          this.$Mesg({content:'二维码生成失败',errOrSuc:'err'})
        }else{
          this.dataURL=baseurl
          this.ewmShow=true
        }
      })
    },
    getNameAndSon(name,sonId){
      let str=''
      const{son}=this.payData[name]
      str+=this.payData[name].name
      let sonObj=son.find(e=>e.id==sonId)
      if(sonObj){
        str+="-"+sonObj.name
      }
      return str
    },
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnLBClass(lb){
      if(lb==1){
        return 'err'
      }
      else if(lb==2){
        return 'suc'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '未支付'
      }
      else if(lb==2){
        return '支付成功'
      }
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/pays/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }
      }).catch(() => {
        this.$Mesg({
          content: '获取数据失败！',
          errOrSuc: 'err',
        })
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    
  },
}
</script>
